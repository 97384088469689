import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import Reports from "../components/hire/Reports";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire GatsbyJS Developers`,
    text: "Get Future-Driven Expertise",
    content: "Hire GatsbyJS developers from Octet to change your business and boost visibility through optimized user experiences.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Designer',
      'Time-Zone Friendly'
    ],
    linkText: 'Hire GatsbyJS Developers',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'GatsbyJS development for Hyper-performant Apps',
    text: "Our Gatsby developers construct high-speed web applications that are simple to create, manage, and deploy.",
    list: [
      {
        title: 'Enhance the User Experience',
        text: 'Our Gatsby developers understand how to structure material, develop user-friendly applications, and use responsive design to guarantee the function effortlessly across all platforms.'
      },
      {
        title: 'Performance Optimization',
        text: 'Our Gatsby developer creates websites and apps with clean code, fast loading speeds, and responsiveness, essential for search engine ranking.'
      },
      {
        title: 'Ongoing Support',
        text: 'Our GatsbyJS development offers retainer packages for ongoing support, website updates, and strategic advice to help you maximize the site’s potential.'
      }
    ],
    image: ["hire-content-gatsby.webp"],
    titleText: 'Gatsby Developers',
    alt: 'Benefits of hiring Gatsby developers'
  }
  const commonContent2 = {
    title: 'Get Octet’s Top Gatsby Developers',
    text: "Leading companies trust Octet Design Studio to hire GatsbyJS developer to acquire top design expertise with industry-specific experience.",
    list: [
      {
        title: 'Hire GatsbyJS Developer In 48 Hours',
        text: 'Our top Gatsby developers ensure swift recruitment processes, bringing aboard and hiring the best mobile app designers within 48 hours.'
      },
      {
        title: 'Transparent Reporting',
        text: 'Stay in control with detailed timesheet reports from our GatsbyJS developer to track progress and productivity.'
      },
      {
        title: '160 hours of Dedicated Work',
        text: 'Our Gatsby developers commit 160 hours of focused, high-quality work, ensuring productivity and results.'
      },
      {
        title: 'Seamless Collaboration',
        text: 'Hire GatsbyJS developer and enjoy seamless collaboration with a dedicated manager who oversees your design needs and ensures a smooth workflow.'
      }
    ],
    image: ["hire-content-2-gatsby.webp"],
    titleText: 'Hire GatsbyJS developers',
    alt: 'Hire Gatsby developers from a leading GatsbyJS web development agency'
  }
  const commonContent3 = {
    title: `3 Steps to Hire Gatsby Developer`,
    text: "Experience the Octet advantage by hiring Gatsby developers in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: "Provide us with your project requirements, and we will share the resumes of our developers who best match your needs and business goals."
      },
      {
        title: 'Step 2: Interview Designers',
        text: "Interview and evaluate our skilled Gatsby developers to select the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire GatsbyJS developers, onboard them within 48 hours so they can dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-3-gatsby.webp"],
    titleText: 'Hire Gatsby developer',
    alt: 'Simple steps to hire Gatsby developer from Octet'
  }
  const cta = {
    title: `Have a GatsbyJS Project in Mind?`,
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "eCommerce Gatsby Developers",
      content: "Our Gatsby developers use various tools and software to develop apps that meet users' needs and help convert visitors into customers. Hire GatsbyJS developers to create an enjoyable and seamless shopping experience for customers, which can increase sales and customer loyalty.",
    },
    {
      title: "UI UX Designers for SaaS",
      content:
        "SaaS GatsbyJS developers specialize in SaaS platforms and create visually appealing and user-friendly designs for web and mobile applications. Hire GatsbyJS developer who will be a creative problem solver and develop beautiful and functional apps and websites that enhance the user experience.",
    },
    {
      title: "Enterprise Gatsby Developer",
      content: "Hire GatsbyJS developer who will allow you to deliver scalable solutions for large-scale operations. These developers will address intricate workflows and compliance needs with precision and user-centric strategy. Our Gatsby developer will work closely with developers and product managers to ensure the final mobile app design meets the enterprise's and its users' needs.",
    },
  ];
  const tabsData = {
    title: `Hire GatsbyJS Developers for Faster Growth`,
    des: `Our Gatsby developers are expertise in React, Node.js, GraphQL, JSON, and Webpack to create well-designed, SEO-friendly apps and feature-rich UIs for company optimization.`,
    tabs: ["Web App Development ", "Custom App Development", "SaaS Web Development", "Desktop App Designing"],
    slides: [
      {
        title: 'Web App Development',
        desc1: "Your business solutions will gain a performing position from progressive web apps. Make a significant improvement in the client experience with our GatsbyJS development services and PWAs, which are made to satisfy the needs of all corporate levels..",
        // desc2: 'Hire UI designer for mobile app designing to obtain consistent user-centric design, better accessibility, and intuitive layouts.',
        list: [
          'Building Rich Ecosystem',
          'Providing Efficient Development',
          'Expertise in Enhancing Security'
        ]
      },
      {
        title: 'Custom App Development',
        desc1: "Do you have any app needs that are specific to your business plan? Hire GatsbyJS developers from us to benefit from cutting-edge, highly scalable, and secure applications designed to meet your demands.",
        // desc2: 'Enhance your brand’s online presence with a focus on user-friendly, innovative design solutions tailored to your unique web UI UX needs.',
        list: [
          'Ability to Boost Maximum Visibility',
          'Delivering High Scalability',
          'Creating Feature-rich UIs'
        ]
      },
      {
        title: 'SaaS Web Development',
        desc1: "With our GatsbyJS web development agency, tech firms and SaaS companies can create feature-rich product websites that seamlessly integrate with marketing tools and offer a positive user experience..",
        // desc2: 'Our UI UX experts create visually appealing mobile designs that ensure conciseness and efficiency to deliver a better user experience.',
        list: [
          'Proficiency in utilizing React',
          'Expertise in optimized Performance',
          'Developing SEO-friendly Solutions'
        ]
      },
      {
        title: 'Desktop App Designing',
        desc1: "Gatsby development can be used with headless CMSs and e-commerce platforms like Shopify, Magento, and BigCommerce. This approach allows our Gatsby developers to create highly customized and performant online stores, leveraging Gatsby's static site generation for fast load times.",
        // desc2: "Our UI UX designers will create workflows and optimize user experience for desktop devices to meet users' needs and expectations.",
        list: [
          'Expertise in fast user-experiences',
          'Boosting Online Presence',
          'Ensuring Data Safety'
        ]
      }
    ],
    altOne: 'Progressive Web App Development',
    titleOne: 'Progressive Web App Development',
    altTwo: 'Custom App Development',
    titleTwo: 'Custom App Development',
    altThree: 'SaaS Web Development',
    titleThree: 'SaaS Web Development',
    altFour: 'Desktop App Designing',
    titleFour: 'Desktop App Designing',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "Simplifying Crew Management Process",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"]
    },
    {
      title: "Survey2Connect",
      desc: "Streamlining Survey Collection Solution",
      Industry: "Enterprise",
      firstAlt: "UI UX Design To Create Survey",
      firstTitle: "Survey Creation Screen",
      secondAlt: "Ui Design To Create Questionnaire For Survey",
      secondTitle: "Questionnaire For Survey",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp", "survey-3.webp"],
    },
    {
      title: "Elastic Sign",
      desc: "Efficient Design for Secure Document Signing",
      Industry: "SaaS",
      firstAlt: "UI Design to View all the Documents and their Status",
      firstTitle: "Manage Documents UI Design",
      secondAlt: "UI UX Design to Sign Documents Digitally",
      secondTitle: "UI Design to Sign Digital Documents",
      thirdAlt: "UI Screen to draw, upload or type your own signature",
      thirdTitle: "Create Your Own Signature",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"]
    },
    {
      title: "Unilogix App",
      desc: "Comprehensive Logistic Analytics Suite",
      Industry: "Logistics",
      firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
      firstTitle: "Logistics Services List",
      secondAlt: "Order And Warehouse Details",
      secondTitle: "Order Details",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp", "uni-logics-3.webp"],
    },
    {
      title: "Ship Delight",
      desc: "User-Centric Logistic Website Redesign",
      Industry: "Logistics",
      firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "UI Dashboard To Check The Performance Status",
      secondTitle: "NDR Status",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp", "ship-delight-web-3.webp"],
    },
  ];
  const lastLength = projectData;
  const itemsList = [
    {
      title: '200+',
      text: 'Successful Projects '
    },
    {
      title: '30+',
      text: 'Experienced Designers'
    },
    {
      title: '80+',
      text: 'Satisfied Clients'
    }
  ];
  const reports = {
    title: `Hire Designers from <span class="h1-span">Octet vs Others</span>`,
    text: "Choosing Octet means efficient execution and a speedier ROI. Our Gatsby developers are passionate and committed to developing captivating apps, websites, and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s UI UX Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'GatsbyJs Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const ourData = {
    title: 'Hear From Our Clients',
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['visa.webp'],
        stars: 4,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      }
    ]
  };
  const Questions = [
    "Why should you Hire GatsbyJS Developers from Octet?",
    "How can you Hire GatsbyJS developers from Octet?",
    "Which companies Hire GatsbyJS Developers?",
    "Does your GatsbyJS Developer come with a trial period?",
    "How does our transparent pricing system work?"
  ];
  const faqData = [
    {
      para: [
        `Octet boasts a skilled, creative, and functional team of Gatsby developers who specialize in crafting engaging and functional interfaces.`,
        `Our Gatsby developer has a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends.`,
        `Hire GatsbyJS developers from Octet to ensure your product remains relevant and competitive.`
      ]
    },
    {
      para: [
        `Hiring Gatsby developers from Octet involves just 3 easy steps:`,
        `<strong><h4>Share Your Requirements:</h4></strong>`,
        `Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable developer to meet your needs and expectations..`,
        `<strong><h4>Interview Designers:</h4></strong>`,
        `Once we understand your needs, we will share a list of developers who match your project needs.`,
        `You can then interview and evaluate our developers to assess their skills, experience, and creativity.`,
        `Choose the candidate who best matches your project goals.`,
        `<strong><h4>Onboard and Begin:</h4></strong>`,
        `After hiring GatsbyJS developers from us, onboard them onto your project team and provide them access to necessary resources.`,
      ]
    },
    {
      para: [
        `Gatsby developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.`,
        `Whether designing mobile app, building websites, software interfaces, or consumer electronics, our app designers for hire can enhance usability, functionality, and visual appeal for different companies.`,
      ]
    },
    {
      para: [
        `Hiring GatsbyJS experts from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company.`,
      ]
    },
    {
      para: [
        `At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services.`,
        `Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Redefine web Apps with Our Gatsby Developers",
    para: 'Being a top-tier GatsbyJS web development agency, Octet has quickly and affordably solved complicated website development issues.',
    content: [
      {
        title: "User Research",
        text: "Hire GatsbyJS developers who can closely examine the behavior of your target market to achieve steady growth. Our GatsbyJS development delivers user-friendly and efficient solutions by learning about their preferences and routines.",
      },
      {
        title: "Custom Web Development",
        text: "Hire GatsbyJS developers to create websites that look great and function well on all devices. With our GatsbyJS development, you can ensure fast load times and smooth user experiences.",
      },
      {
          title: "Usability Testing",
          text: "Our Gatsby developers analyze website performance and provide recommendations for improvements. They ensure your website meets accessibility standards for all users.",
        },
        {
            title: "UI UX Design",
            text: "Our Gatsby developers conduct research to understand user needs and preferences. Hire them to craft visually appealing, cohesive designs that align with your brand identity.",
        },
        {
          title: "GatsbyJS Expertise",
          text: "Hire GatsbyJS developers to utilize Gatsby's static site capabilities for fast, secure, and scalable websites. Our Gatsby developer creates custom GatsbyJS plugin development to extend website functionality.",
        },
      {
        title: "Wireframing and Prototyping",
        text: "As a top GatsbyJS web development agency, our process involves meticulously crafting wireframes and interactive prototypes to bring design concepts to life. These visualizations allow us to refine user experience before moving forward with development.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why should you Hire GatsbyJS Developers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet boasts a skilled, creative, and functional team of Gatsby developers who specialize in crafting engaging and functional interfaces. 
          
          Our Gatsby developer has a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends. 
          
          Hire GatsbyJS developers from Octet to ensure your product remains relevant and competitive."
              }
            },{
              "@type": "Question",
              "name": "How can you Hire GatsbyJS developers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring Gatsby developers from Octet involves just 3 easy steps:
          
          Share Your Requirements:
          Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable developer to meet your needs and expectations.
          
          Interview Designers:
          Once we understand your needs, we will share a list of developers who match your project needs.
          You can then interview and evaluate our developers to assess their skills, experience, and creativity.
          Choose the candidate who best matches your project goals.
          
          Onboard and Begin:
          After hiring GatsbyJS developers from us, onboard them onto your project team and provide them access to necessary resources."
              }
            },{
              "@type": "Question",
              "name": "Which companies Hire GatsbyJS Developers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Gatsby developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.
          
          Whether designing mobile app, building websites, software interfaces, or consumer electronics, our app designers for hire can enhance usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Does your GatsbyJS Developer come with a trial period?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring GatsbyJS experts from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company."
              }
            },{
              "@type": "Question",
              "name": "How does our transparent pricing system work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services. 
          
          
          Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money."
              }
            }]
          }                                    
        `}
      </script>
    </Helmet>
      <Layout hire="Hire Our GatsbyJS Developers">
        <div>
          <HireBanner data={banner} pageName="Hire GatsbyJS Developers" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Hire Industry's Top Gatsby Developers`} text={``} />
          <ContentCommon data={commonContent3} big reverse />
          {/* <ContentCommon data={commonContent4} big /> */}
          {/* <OurWorkslef data={ourData} /> */}
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} des={'Explore common queries about our Gatsby developers, hiring Gatsby developers, pricing, and processes to gain clarity and confidence in your decision-making journey.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;
export const Head = () => (
  <Seo
    title="Hire GatsbyJS Developers | GatsbyJS Development"
    description="Hire Gatsby Developers from Octet to create cutting-edge websites and online programs. Our Gatsby developers create websites with dynamic features."
  />
);